
  @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;600;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --font-family: font-family: 'Orbitron', sans-serif;
    --clr-background: #160724;
    --clr-greyaczent: #626063;
    --clr-benefits: #260E37;
    --clr-neongreen: #39FF14
}



body {
    background-color: black;
}